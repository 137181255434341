/* eslint-disable react/jsx-indent-props */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-indent */
/* eslint-disable import/order */
import * as React from 'react';
import { FC } from 'react';
import { TFunction } from 'i18next';
import * as rdd from 'react-device-detect';

import { Image } from '../../ui/image';
import { ImageGallery } from '../../ui/imageGallery';
import { FullWidthSliderLogin } from '../common/elements/Slider';

const SectionLogin: FC<{ translation: TFunction }> = ({ translation }) => {
  const methods = translation('methods', {
    returnObjects: true,
  });

  const posInfo = translation('posInfo', {
    returnObjects: true,
  });
  
  return (
    <section className="section-login">
      <div className="section-login--container card-container container">
        <div className="card-container--main">
          <h1 className="card-container--title title">{translation('headline')}</h1>
          <div className="card-container--wrapper">          
              {Object.keys(methods).map((key) => {
                  const { imgOne, textBold, firstText, text, link, classN} = methods[
                      key
                  ];
                  return ( 
                      <a className={`card-container--component card-component ${classN}`} key={textBold.split(' ').join('')} href={link}>
                            <div className="card-component--wrapper">
                              <div className="card-component--container">
                                <h2 className="card-component--title">
                                  <span className={`${classN}`}>{firstText}</span>
                                  <br />
                                  {textBold}
                                </h2>
                                <p className="card-component--text">
                                    {text}
                                </p>    
                              </div>
                              <div className="card-component--img">
                                <Image
                                  name="arrow-right.svg"
                                  className={`${classN} inline-svg inline-icon`}
                                  alt="arrow icon"
                                />
                              </div>
                            </div>  
                            <ImageGallery
                                className="card-component--bkg"
                                name={imgOne}
                                alt="illustartion image"
                                hero
                            />
                      </a>
                  )}
              )}
          </div>
        </div> 
      </div>

      {rdd.isDesktop && (
        <div className="more-info is-hidden-touch">
          <div className="more-info--container">
            <FullWidthSliderLogin slides={posInfo} />
          </div>         
        </div>
        )}
    </section>
  );
};

export { SectionLogin };
